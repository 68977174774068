"use client";
import api from "@/lib/common/api";
import { User } from "@prisma/client";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import TopBarProgress from "react-topbar-progress-indicator";
import { useDebounceValue } from "usehooks-ts";
import { removeDatesFromObject } from "../utils";

interface Options<T> {
  initialData?: FeedApiData<T[]>;
  additionalQuery?: any;
  ItemTemplate: any;
  HeaderTemplate?: any;
  search?: string;
  src: string;
  limit?: number;
  noTable?: boolean;
}

export function Feed<T extends { id: any }>(props: Options<T>) {
  const { isLoading, data, skip, setSkip, setSearch, setKeepPreviews } =
    useFeed(props);

  return (
    <>
      {props.noTable ? (
        <>
          {props.HeaderTemplate && (
            <props.HeaderTemplate
              onSearch={(val: string) => {
                setSearch(val);
                setSkip(0);
              }}
            />
          )}
          {data?.data.map((item) => (
            <props.ItemTemplate
              key={item.id}
              {...removeDatesFromObject(item)}
            />
          ))}
        </>
      ) : (
        <>
          {" "}
          <table className="table w-full table-fixed">
            <thead>
              <tr>
                {props.HeaderTemplate && (
                  <props.HeaderTemplate
                    onSearch={(val: string) => {
                      setSearch(val);
                      setSkip(0);
                    }}
                  />
                )}
              </tr>
            </thead>
            <tbody>
              {data?.data.map((item) => (
                <tr key={item.id}>
                  <props.ItemTemplate {...removeDatesFromObject(item)} />
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {isLoading && <TopBarProgress />}

      <div className="flex w-full items-center justify-end gap-3">
        <button
          className="btn"
          disabled={skip <= 0}
          onClick={async () => {
            setKeepPreviews(true);
            setSkip((s) => s - 1);
          }}
        >
          {"<"}
        </button>
        <div className="flex gap-2">
          {";"
            .repeat(data?.info.pages || 1)
            .split("")
            .map((_, i) =>
              (data?.info.pages || 0) > 5 && i != skip
                ? i < 3 || i > (data?.info.pages || 0) - 4
                : true
            )
            .map(
              (p, i) =>
                p && (
                  <button
                    key={i}
                    className="btn btn-square btn-ghost"
                    disabled={i == skip || isLoading}
                    onClick={() => setSkip(i)}
                  >
                    {i + 1}
                  </button>
                )
            )}
        </div>
        <button
          className="btn"
          disabled={
            !data?.info.theresMore || data.info.pages == skip + 1 || isLoading
          }
          onClick={async () => {
            setKeepPreviews(true);
            setSkip((s) => s + 1);
          }}
        >
          {">"}
        </button>
      </div>
    </>
  );
}

export function useFeed<T>(props: {
  src: string;
  limit?: number;
  skip?: number;
  search?: string;
  initialData?: FeedApiData<T[]>;
  additionalQuery?: any;
  customUseQueryOptions?: Parameters<typeof useQuery>[2];
  periodically?: boolean;
}) {
  const [keepPreviews, setKeepPreviews] = useState(true);
  const [search, setSearch] = useState("");
  const [skip, setSkip] = useState(0);
  const [searchDebounced] = useDebounceValue(search, 500);
  const [skipDebounced] = useDebounceValue(skip, 500);

  const fetchData = async () => {
    //   if (!props.search?.length) return setItems(props.initialData || []);
    const data: FeedApiData<T> = await api(
      `/api${props.src[0] == "/" ? "" : "/"}${props.src}?${new URLSearchParams({
        skip: String(skipDebounced),
        limit: String(props.limit ?? 10),
        search: searchDebounced,
        ...props.additionalQuery,
      }).toString()}`,
      {
        method: "GET",
      }
    );
    return data;
  };

  const { isLoading, data, refetch } = useQuery(
    [
      props.src,
      searchDebounced,
      skipDebounced,
      JSON.stringify(props.additionalQuery),
    ],
    fetchData,
    // @ts-ignore
    {
      keepPreviousData: keepPreviews,
      cacheTime: 60 * 1000,
      ...(props.initialData && { initialData: props.initialData }),
      //Keep refetching every 5 seconds while we don't stop it
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
      // refetchOnWindowFocus: true,
      ...(props.customUseQueryOptions as any),
    }
  );

  return {
    isLoading,
    data,
    search,
    skip,
    setSkip,
    setSearch,
    setKeepPreviews,
    searchDebounced,
    skipDebounced,
    fetchData,
    mutateAsync: async (id: string, newData: Partial<T> | undefined, method?: string) => {
      await api(`/api${props.src[0] == "/" ? "" : "/"}${props.src}/${id}`, {
        method: method || "POST",
        body: newData,
      });

      await refetch();
    },
  };
}
